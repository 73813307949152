<template>
  <div class="content">
    <div class="handle-box">
      <el-button type="primary" icon="el-icon-circle-plus-outline" class="mr20" @click="$refs.formDialog.formVisible = true">添加团队成员</el-button>
    </div>
    <!-- 列表 -->
    <el-table :data="list" @sort-change="changeTableSort">
      <el-table-column label="" width="20"></el-table-column>
      <el-table-column prop="name" label="成员名称" align="left"  ></el-table-column>
      <el-table-column prop="job" label="职位" align="left"  ></el-table-column>
           <el-table-column prop="image" label="主展示图片">
        <img  style="width:100px;height:100px;border-radius:4px;"  slot-scope="scope" :src="scope.row.image" mode="aspectFill"></img>
      </el-table-column>
       <el-table-column prop="sort" label="排序" align="left"  ></el-table-column>
      <el-table-column prop="status" label="是否启用" align="center">
        <el-switch slot-scope="scope" v-model="scope.row.status" :width="36" :active-value="1" :inactive-value="0" @change="stateChange(scope.$index, scope.row)"></el-switch>
      </el-table-column>
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" @click="edit(scope.row)">编辑</el-button>
          <el-button type="danger" icon="el-icon-delete" @click.native.prevent="toDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @current-change="pagination" layout="total,prev,pager,next,jumper" :current-page="page" :page-size="pageLimit" :total="totalSize"></el-pagination>
    </div>
    <!-- 新增、编辑 -->
    <notice-manage ref="formDialog" @refreshData="loadList"></notice-manage>
  </div>
</template>

<script>
import noticeManage from './team-manage';
import http from '@/utils/cloud';

export default {
  components: {
    noticeManage,
  },
  data() {
    return {
      project:localStorage.getItem('mall-project'),
      list: [],
      page: 1,
      pageLimit: 20,
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    async loadList() {
      const { page, pageLimit } = this;
      const res = await http.POST('/jw-admin', {
        module: 'team',
        operation: 'getList',
        offset: (page - 1) * pageLimit,
        limit: pageLimit,
        project: JSON.parse(this.project)._id
      });

      this.list = res.data;
      this.totalSize = res.affectedDocs;
      this.loaded = true;
    },
    /* 启用 | 禁用 */
    async stateChange(index, item) {
      const response = await http.POST('/jw-admin', {
        module: 'team',
        operation: 'setStatus',
        id: item._id,
        status: item.status,
      });

      if (response.updated !== 1) {
        this.$message.error(response.msg || '操作失败');
        item.status = item.status == 1 ? 0 : 1;
      }
    },
    /* 删除 */
    toDelete(index, item) {
      this.$confirm(`是否要删除：${item.name}`, '删除提示', {
        confirmButtonText: '删除',
        type: 'warning',
      })
        .then(async () => {
          const response = await http.POST('/jw-admin', {
            module: 'team',
            operation: 'remove',
            id: item._id,
          });

          if (response.status === 1) {
            this.$message.success('删除成功');
            this.list.splice(index, 1);
          } else {
            this.$message.error(response.msg);
          }
        })
        .catch(() => {});
    },
    /* 编辑 */
    edit(item) {
      this.$refs.formDialog.formData = JSON.parse(JSON.stringify(item));
      this.$refs.formDialog.formVisible = true;
    },
  },
};
</script>

<style scoped lang="scss"></style>
